// import { lazy, Suspense } from "react";
// import gql from "graphql-tag";
// import Query from "../utils/Query";

// const MetaComp = (lazy(() => (import('../comp/MetaComp'))));


function EndPage(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <div className="flex dark:bg-pgray-900 bg-pwhite relative mt-20">
        <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
          <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
            <div className="grow sm:text-center lg:text-left mt-20 mr-24">
              <h1 className="relative tracking-tight text-pblue-600 text-6xl sm:text-8xl">
                <span className="">
                  Thank You
                </span>
              </h1>
              <h1 className="relative tracking-tight dark:text-pwhite dark:text-pwhite/70 text-pgray-600/60 text-6xl sm:text-8xl">
                <span className="">
                  for Being Part of
                </span>
              </h1>
              <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-6xl sm:text-8xl">
                <span className="">
                  PARSIQ’s Journey
                </span>
              </h1>
              <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 text-pgray-600 dark:text-pwhite/70 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
                Over the years, PARSIQ has provided Web3 developers with user-friendly development tools.
              </p>
              <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 text-pgray-600 dark:text-pwhite/70 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
                As the Web3 ecosystem evolves, so does our mission, and we’ve channeled our efforts into creating something powerful: <span className="dark:text-pwhite text-pgray-900">Reactive Network</span>
              </p>
            </div>
          </main>

          <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
            <div className="grow lg:text-left mt-20">
              <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-5xl">
                <div className="font-medium">
                  Discover Reactive Network
                </div>
              </h1>
              <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 ">
                Reactive Network is at the forefront of blockchain evolution, offering a fully EVM-compatible execution layer that empowers developers to build the next wave of decentralized applications. Unlike traditional smart contracts, Reactive Smart Contracts leverage Inversion-of-Control to autonomously react to on-chain events, driving seamless interactions across multiple blockchains without user intervention.
              </p>
              <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 ">
                Learn more about Reactive Network:
              </p>

              <div className="mt-5 sm:mt-8 flex lg:justify-start gap-4 sm:gap-8">
                <div className="rounded-xl">
                  <a href="https://reactive.network/?utm_source=parsiq" className="transition-colors ease-in-out w-full flex items-center justify-center px-8 py-3 text-base font-medium font-sans rounded-xl text-pwhite hover:text-pwhite dark:text-pwhite dark:bg-pblue-500 bg-pgray-700 hover:bg-pgray-800 hover:dark:bg-pblue-600 md:py-3 md:text-lg md:px-10 border-pgray-700 border-2 dark:border-pblue-500 hover:dark:border-pblue-600 ">
                    Visit Reactive Network
                  </a>
                </div>
              </div>
            </div>
          </main>

          <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
            <div className="grow lg:text-left mt-20">
              <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-5xl">
                <div className="font-medium">
                  Seamless Migration: 1:1 Transition Between PRQ and REACT
                </div>
              </h1>
              <p className="leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl mt-3 md:mt-5 lg:mx-0 ">
                We understand the importance of a fair and transparent token migration process. Therefore, we are pleased to confirm that the swap from PRQ to REACT will be on a 1:1 token basis. There will be no lockups for existing PRQ holders, — just a straightforward transition, fair for holders and the community.
              </p>
            </div>
          </main>

          <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative mb-20">
            <div className="grow lg:text-left mt-20">
              <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-5xl">
                <div className="font-medium">
                  Toward a blockchain revolution
                </div>
              </h1>
              <p className="leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl mt-3 md:mt-5 lg:mx-0 ">
                We continue to support developers through the Reactive Network project. We greatly appreciate your support of PARSIQ all these years and hope you will join us in shaping the future with Reactive Network.
              </p>
              <p className="leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl mt-3 md:mt-5 lg:mx-0 ">
                Join the Reactive Network community
              </p>
              <p className="leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl mt-3 md:mt-5 lg:mx-0 ">
                <a href="https://t.me/parsiq_group" className="dark:text-pblue-600 text-pblue-600 hover:dark:text-pwhite hover:text-pgray-900 underline">Telegram</a> and <a href="https://x.com/parsiq_net" className="dark:text-pblue-600 text-pblue-600 hover:dark:text-pwhite hover:text-pgray-900 underline">X</a>
              </p>
              <p className="leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite text-pgray-900 sm:mt-5 sm:max-w-xl mt-3 md:mt-5 lg:mx-0 ">
                Let’s keep buidling Web3 together! 
              </p>
              <svg
                    className="my-auto hidden lg:block h-10 w-auto sm:h-10 dark:text-pwhite text-pgray-900 mt-16"
                    width="201"
                    height="48"
                    viewBox="0 0 201 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6303 32.2124V37.3371H15.7655V36.0559H22.8265V47.3119C10.9971 46.6713 1.36847 36.9711 0.726562 25.166H12.0058V32.1209L10.6303 32.2124Z"
                      fill="currentColor"
                    />
                    <path
                      d="M32.4555 36.0557V37.3369H37.5908V32.2122H36.3069V25.2573H47.5862C46.9443 37.0624 37.224 46.6711 25.3945 47.3117V36.0557H32.4555Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.2432 8.87646C67.4835 8.87646 64.1823 10.2492 61.8897 12.8115V9.42554H57.3047V45.5728H61.8897V32.4866C64.274 34.9574 67.5752 36.4216 71.2432 36.4216C78.9461 36.4216 84.9067 30.3818 84.9067 22.6948C84.9067 14.9163 78.9461 8.87646 71.2432 8.87646ZM71.1515 13.3606C76.2868 13.3606 80.4133 17.3871 80.4133 22.6033C80.4133 27.8194 76.3785 31.846 71.1515 31.846C66.0163 31.846 61.8897 27.8194 61.8897 22.6033C61.8897 17.3871 65.9246 13.3606 71.1515 13.3606Z"
                      fill="currentColor"
                    />
                    <path
                      d="M111.134 12.72C108.75 10.2492 105.449 8.87646 101.781 8.87646C94.0778 8.87646 88.1172 14.9163 88.1172 22.6033C88.1172 30.2903 94.1695 36.33 101.781 36.33C105.449 36.33 108.75 34.9574 111.134 32.4866V35.781H115.811V9.33402H111.134V12.72ZM101.964 31.846C96.8288 31.846 92.7022 27.8194 92.7022 22.6033C92.7022 17.3871 96.7371 13.3606 101.964 13.3606C107.099 13.3606 111.134 17.3871 111.134 22.6033C111.134 27.8194 107.099 31.846 101.964 31.846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M134.977 9.05945H133.877C130.942 9.05945 128.558 9.97456 126.816 11.7133V9.42549H122.23V35.8724H126.816V21.322C126.816 14.1841 132.226 13.635 133.877 13.635H134.977V9.05945Z"
                      fill="currentColor"
                    />
                    <path
                      d="M149.008 20.4985C145.431 19.5834 143.322 18.9428 143.322 16.838C143.322 14.4587 145.706 13.3606 148.091 13.3606C150.567 13.3606 152.584 14.4587 153.318 16.3805L153.776 17.5701L157.719 15.2823L157.352 14.3672C155.794 10.8897 152.401 8.87646 148.091 8.87646C142.68 8.87646 138.737 12.2624 138.737 16.838C138.737 22.3287 143.689 23.6099 147.724 24.708C151.3 25.6232 153.501 26.3553 153.501 28.46C153.501 31.0224 150.842 31.9375 148.274 31.9375C145.156 31.9375 142.864 30.6563 142.13 28.3685L141.672 27.0874L137.637 29.4667L137.912 30.2903C139.379 34.1338 143.139 36.4216 148.182 36.4216C153.96 36.4216 157.994 33.1272 157.994 28.46C157.994 22.7863 152.951 21.5051 149.008 20.4985Z"
                      fill="currentColor"
                    />
                    <path
                      d="M168.171 9.42578H163.586V35.8727H168.171V9.42578Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.142 9.42554V12.8115C193.758 10.3407 190.456 8.87646 186.788 8.87646C179.086 8.87646 173.125 14.9163 173.125 22.6033C173.125 30.2903 179.177 36.3301 186.788 36.3301C190.548 36.3301 193.849 34.9574 196.142 32.395V45.4812H200.727V9.33402H196.142V9.42554ZM186.88 31.846C181.745 31.846 177.618 27.8194 177.618 22.6033C177.618 17.3871 181.653 13.3606 186.88 13.3606C192.107 13.3606 196.142 17.3871 196.142 22.6033C196.142 27.8194 192.107 31.846 186.88 31.846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M169.091 0H162.672V6.40584H169.091V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M22.8265 11.9882H32.5468V10.4324H37.6821V16.1062H32.5468V14.5505H22.8265V25.1659L33.8306 25.2574V32.2123H32.5468V33.4935H15.7655V32.2123H14.5734V15.5571H15.8572V10.4324H10.722V15.5571H12.0058V22.512H0.726562C1.36847 10.707 11.0888 1.09823 22.8265 0.457642V11.9882Z"
                      fill="currentColor"
                    />
                  </svg>
            </div>
          </main>

        </div>
      </div>
    </>
  );
}

export default EndPage;