import React from "react";

import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./styles/tailwind.css";

import { ApolloProvider } from "@apollo/client";
import { HelmetProvider } from "react-helmet-async";
import client from "./utils/apolloClient";
import ScrollToHashElement from "./utils/ScrollToHashElement";

import Navbar from "./comp/Navbar2023Comp";
import { FooterComp } from "./comp/FooterComp";

import ErrorPage from "./comp/ErrorPage";
import Error503Page from "./comp/Error503Page";
// import HomePage from "./comp/HomePage";
import TsunamiPage from "./comp/TsunamiPage";
import DataLakesPage from "./comp/DataLakesPage";
import CommunityPage from "./comp/CommunityPage";
import PricingPage from "./comp/PricingPage";
import TOCPage from "./comp/TOCPage";
import PrivacyPolicyPage from "./comp/PrivacyPolicyPage";
import AboutUsPage from "./comp/AboutUsPage";
import SEOBlockchainPage from "./comp/SEOBlockchainPage";
import TokenPage from "./comp/TokenPage";
import HomeEarly2023Page from "./comp/HomeEarly2023Page";
import RoadmapPage from "./comp/RoadmapPage";
import UseCasesPage from "./comp/UseCasesPage";
import CaseStudiesPage from "./comp/CaseStudiesPage";
import EndPage from "./comp/EndPage";

function CheckLocation(param) {
  switch (param) {
    case "/":
      return "Network";
    case "/tsunami":
      return "Tsunami API";
    case "/lakes":
      return "Data Lakes";
    case "/home":
      return "Network";
    case "/end":
      return "Reactive Network";
    default:
      return "";
  }
}

function App() {
  const [mode, setMode] = useState(
    localStorage.theme
      ? localStorage.theme
      : window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"
  );

  const onSelectMode = (mode) => {
    setMode(mode);
    if (mode === "dark") document.body.classList.add("dark");
    else document.body.classList.remove("dark");

    if (mode === "dark") localStorage.theme = "dark";
    else localStorage.theme = "light";
  };

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        onSelectMode(e.matches ? "dark" : "light")
      );

    if (localStorage.theme)
      onSelectMode(localStorage.theme === "dark" ? "dark" : "light");
    else
      onSelectMode(
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
      );

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  return (
    <div className="relative dark:bg-pgray-900 bg-pwhite dark:selection:text-pwhite dark:selection:bg-pblue-500 overflow-hidden">
      <HelmetProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            {/* <Navbar
              mode={mode}
              location={CheckLocation(window.location.pathname)}
            /> */}
            <Routes>
              {/* <Route path="*" element={<ErrorPage mode={mode} />} /> */}
              <Route path="*" element={<EndPage mode={mode} />} />

              {/* <Route path="/oldhomepage" element={<HomePage mode={mode} />} /> */}
              {/* <Route path="/" element={<HomeEarly2023Page mode={mode} />} /> */}
              {/* <Route path="/tsunami" element={<TsunamiPage mode={mode} />} />
              <Route path="/lakes" element={<DataLakesPage mode={mode} />} />

              <Route
                path="/chains/:slug"
                element={<SEOBlockchainPage mode={mode} />}
                exact
              />

              <Route
                path="/usecase/:slug"
                element={<UseCasesPage mode={mode} />}
                exact
              />

              <Route
                path="/casestudy/:slug"
                element={<CaseStudiesPage mode={mode} />}
                exact
              />

              <Route path="/about-us" element={<AboutUsPage mode={mode} />} />
              <Route
                path="/community"
                element={<CommunityPage mode={mode} />}
              />
              <Route path="/pricing" element={<PricingPage mode={mode} />} />
              <Route path="/token" element={<TokenPage mode={mode} />} />
              <Route path="/roadmap" element={<RoadmapPage mode={mode} />} />

              <Route
                path="/terms-and-conditions"
                element={<TOCPage mode={mode} />}
              />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicyPage mode={mode} />}
              /> */}

              <Route
                path="/"
                element={<EndPage mode={mode} />}
              />

              <Route path="/503" element={<Error503Page mode={mode} />} />

              {/* <Route path="/en" element={<Navigate replace to="/" />} />
              <Route path="/zh" element={<Navigate replace to="/" />} /> */}
            </Routes>
            {/* <FooterComp mode={mode} Changemode={(mode) => onSelectMode(mode)} /> */}
            <ScrollToHashElement />
          </BrowserRouter>
        </ApolloProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
