import React from "react";
import gql from "graphql-tag";
import Query from "../utils/Query";
import HoverShinyEffect from "../utils/HoverShinyEffect";
// import { Helmet } from 'react-helmet-async';

const HOMEAPI_QUERY = gql`
  query Home2023Page {
    home2023 {
      data {
        attributes {
          API {
            __typename,
            ... on ComponentBlockHomeApi {
              BlockHeader {
                Icon,
                PreTitle,
                Title,
                SubTitle
              },
              Block {
                Tag,
                Title,
                Text,
                Text2,
                Text3,
                LightImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                DarkImage {
                  data {
                    attributes {
                      url
                    }
                  }
                },
                ImageText,
                ButtonDisable,
                ButtonExternal,
                ButtonText,
                ButtonURL,
                Icon
              }
            }
          }
        }
      }
    }
  }
`;



function HomePageBenefitsComp(props) {
  const mode = props.mode ? props.mode : "light";

  return (
    <>
      <Query query={HOMEAPI_QUERY} id={null}>
        {({ data: { home2023 } }) => {
          return (
            <>
              <div className="flex dark:bg-pgray-900 bg-pgray-50 relative" id="datalakes">
                <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                  <div className="z-20 relative">
                    <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                      <div className="mr-5 sm:mx-10 w-5 relative">
                        <div className="h-full flex flex-col relative">
                          <div className="bg-gradient-to-t from-pgray-300 to-pgray-300 dark:from-pgray-500 dark:to-pgray-500 w-1 h-[7.5rem] mx-auto rounded-b-xl"></div>
                          <div className="text-porange-500 dark:text-pgray-300 relative my-10 flex-none">
                            <div className="h-6 w-6 animate-pulse" dangerouslySetInnerHTML={{ __html: home2023.data.attributes.API.BlockHeader.Icon }}></div>
                            <div className="absolute top-0 left-0 w-6 h-6 rounded-full bg-porange-400 dark:bg-pgray-400 blur-lg"></div>
                          </div>

                          <div className="h-16 bg-pgray-300 dark:bg-pgray-500 w-1 mx-auto rounded-t-xl grow"></div>
                          <div className="pb-16 bg-gradient-to-b from-pgray-300 dark:from-pgray-500 w-1 mx-auto rounded-t-xl grow"></div>
                        </div>
                      </div>
                      <div className="grow lg:text-left mt-20">
                        <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600/60 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 mb-2" >{home2023.data.attributes.API.BlockHeader.PreTitle}</p>
                        <h1 className="relative tracking-tight dark:text-pwhite text-pgray-900 text-5xl">
                          <div className="font-medium">{home2023.data.attributes.API.BlockHeader.Title}</div>
                        </h1>
                        <p className="mt-3 leading-8 text-2xl sm:text-3xl sm:leading-10 dark:text-pwhite/70 text-pgray-600 sm:mt-5 sm:max-w-xl md:mt-5 lg:mx-0 ">{home2023.data.attributes.API.BlockHeader.SubTitle}</p>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
              <div className="relative bg-gradient-to-b from-pgray-50 dark:from-pgray-900 dark:to-pgray-900">
                <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-6 relative z-20">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 relative">

                    {home2023.data.attributes.API.Block.map((item, i) => (
                      (i == 0 || i == 1) ?
                      <div key={i} className="bg-pgray-50 dark:bg-pgray-900 border border-pgray-300 dark:border-pgray-600 rounded-xl overflow-hidden relative h-[500px] flex">
                        <HoverShinyEffect className="z-[13]" />
                        <div className="w-full p-10 flex flex-row mb-auto relative">
                          <div className="flex flex-col shrink w-full bottom-0 relative">
                            <div className="text-2xl font-medium text-pgray-800 dark:text-pwhite mb-2 z-[14]">{item.Title}</div>
                            <div className="text-xl text-pgray-600 dark:text-pwhite/70 mb-4 z-[14]">{item.Text}</div>
                            <div className="flex items-center z-[30]">
                              {!item.ButtonDisable && 
                                <a href={item.ButtonURL} className="relative transition-colors ease-in-out bg-pwhite hover:bg-pgray-200 dark:bg-pgray-500/50 hover:dark:bg-pgray-500 rounded-full h-9 flex">
                                  <div className="relative pl-3 pr-1.5 my-auto flex flex-row items-center justify-center">
                                    <div className="font-base text-pgray-800 dark:text-pwhite mr-3">{item.ButtonText}</div>
                                    <div className="ext-pgray-800 dark:text-pwhite rounded-full w-8 h-6 flex items-center justify-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </div>
                                  </div>
                                </a>
                              }
                            </div>
                          </div>
                        </div>
                        {/* <div className='absolute top-0 left-0 w-full lg:w-full bg-gradient-to-b dark:from-pgray-900 h-full z-[11] pointer-events-none'></div> */}
                        <div className="absolute bg-contain bg-no-repeat md:bg-cover bg-bottom md:bg-center top-0 left-0 h-full w-full" style={{ backgroundImage: mode !== "dark" ? `url(${process.env.REACT_APP_BACKEND_URL + item.LightImage.data[0].attributes.url})` : `url(${process.env.REACT_APP_BACKEND_URL + item.DarkImage.data.attributes.url})` }}></div>
                      </div>
                      :
                      <div key={i} className="sm:col-span-2 bg-pgray-100 dark:bg-pgray-900 border border-pgray-300 dark:border-pgray-600 rounded-xl overflow-hidden relative h-[500px] flex">
                        <HoverShinyEffect className="z-[13]" />
                        <div className="w-full p-10 flex flex-row mb-auto relative">
                          <div className="flex flex-col shrink w-full bottom-0 relative text-center">
                            <div className="text-2xl text-pgray-800 dark:text-pwhite mb-2 flex flex-row gap-2 items-center justify-center z-[14]">
                              <span className="font-medium">{item.Title}</span>
                              <div className="font-regular text-sm sm:text-sm text-porange-500 leading-tight px-2 py-1 border border-porange-500 rounded-full">{item.Tag}</div>
                            </div>
                            <div className="text-xl text-pgray-800 dark:text-pwhite/70 mb-4 z-[14]">{item.Text}</div>
                            <div className="flex items-center z-[30] mx-auto">
                              {!item.ButtonDisable && 
                                <a href={item.ButtonURL} className="relative transition-colors ease-in-out bg-pwhite hover:bg-pgray-200 dark:bg-pgray-500/50 hover:dark:bg-pgray-500 rounded-full h-9 flex">
                                  <div className="relative pl-3 pr-1.5 my-auto flex flex-row items-center justify-center">
                                    <div className="font-base text-pgray-800 dark:text-pwhite mr-3">{item.ButtonText}</div>
                                    <div className="ext-pgray-800 dark:text-pwhite rounded-full w-8 h-6 flex items-center justify-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </div>
                                  </div>
                                </a>
                              }
                            </div>
                          </div>
                        </div>
                        {/* <div className='absolute top-0 left-0 w-full lg:w-full bg-gradient-to-b dark:from-pgray-900 h-full z-[11] pointer-events-none'></div> */}
                        <div className="absolute bg-contain bg-no-repeat md:bg-cover bg-bottom md:bg-center top-0 left-0 h-full w-full" style={{ backgroundImage: mode !== "dark" ? `url(${process.env.REACT_APP_BACKEND_URL + item.LightImage.data[0].attributes.url})` : `url(${process.env.REACT_APP_BACKEND_URL + item.DarkImage.data.attributes.url})` }}></div>
                      </div>
                    ))}

                  </div>
                </div>

                {/* <div className='-rotate-25 absolute -top-[800px] -left-20 w-[50rem] h-[20rem] blur-4xl bg-gradient-to-r from-pgreen-500 to-pblue-500 rounded-full'></div> */}
                {/* <div className='absolute inset-y-[7rem] left-0 w-full lg:w-full bg-cone h-192 z-[11] scale-150'></div> */}

                <div className="flex relative">
                  <div className="max-w-full md:max-w-7xl mx-auto items-center w-full h-full mt-auto md:my-auto px-4 md:px-0 relative">
                    <div className="z-20 relative">
                      <main className="mx-auto max-w-full lg:max-w-8xl sm:px-6 flex relative">
                        <div className="mr-5 sm:mx-10 w-5 relative">
                          <div className="h-full flex flex-col relative">
                            <div className="bg-gradient-to-t from-pgray-300 dark:from-pgray-500 w-1 h-20 mx-auto"></div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                  {/* <div className='absolute bottom-0 md:inset-y-0 left-0 w-full lg:w-3/5 backdrop-blur-md dark:bg-pgray-800 bg-pgray-50 dark:bg-opacity-50 bg-opacity-30 h-4/6 md:h-full z-10'></div> */}

                  {/* <div className='absolute -top-[400px] left-0 w-[50rem] h-[50rem] blur-3xl bg-gradient-to-r from-pgreen-500 to-pblue-500 z-9 rounded-full'></div> */}
                  {/* <div className='absolute bottom-0 md:inset-y-0 left-0 w-full lg:w-full backdrop-blur-md bg-pgray-800 dark:bg-opacity-50 bg-opacity-30 h-full md:h-full z-10'></div> */}
                </div>

              </div>
            </>
          );
        }}
      </Query>
    </>

  );
}

export default HomePageBenefitsComp;